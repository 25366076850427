<template>
  <div>
    <div class="crumbs">
      <a href="###">首页</a>
      <label>></label>
      <a href="###">贷款申请</a>
      <label>></label>
      <a href="###">采购金贷款申请</a>
    </div>

    <div class="wrap-form-group">
      <el-form
        class="wrap-form"
        size="small"
        label-width="150px"
        label-position="left"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <template>
          <el-form-item label="招标机构">
            <span>{{ bidProject.bidOrg }}</span>
          </el-form-item>
          <el-form-item label="项目名称">
            <span>{{ bidProject.name }}</span>
          </el-form-item>
          <el-form-item label="招标金额">
            <span>{{ bidProject.bidMoney | currency }} 万元</span>
          </el-form-item>
          <el-form-item label="合同金额">
            <span>{{ bidProject.contractMoney | currency }} 万元</span>
          </el-form-item>
          <el-form-item label="统一社会信用代码">
            <span>{{ bidProject.bidCode }}</span>
          </el-form-item>
        </template>

        <el-form-item label="中标金额" prop="googsMoney">
          <el-input v-model="form.googsMoney" placeholder="请输入中标金额">
            <div slot="suffix">万元</div>
          </el-input>
        </el-form-item>

        <el-form-item label="回款周期" prop="returnPeriod">
          <el-input v-model="form.returnPeriod" placeholder="请输入回款周期">
            <div slot="suffix">天</div>
          </el-input>
        </el-form-item>

        <el-form-item label="贷款金额" prop="loanMoney">
          <el-input v-model="form.loanMoney" placeholder="请输入贷款金额">
            <div slot="suffix">万元</div>
          </el-input>
        </el-form-item>
        <el-form-item label="供货方公司">
          <div class="input-row">
            <el-input
              v-model="form.productCompany"
              placeholder="请输入供货方公司"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label-width="0" size="mini">
          <div>
            <el-table :data="form.detailList" size="small" class="qh-table">
              <el-table-column label="产品">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.productName"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="价格">
                <template slot-scope="scope">
                  <el-input-number
                    v-model="scope.row.productPrice"
                    :controls="false"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="数量">
                <template slot-scope="scope">
                  <el-input-number
                    v-model="scope.row.productAmount"
                    :controls="false"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="">
                <template slot-scope="scope">
                  <el-button type="text" @click="removeDetailRow(scope.$index)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div style="text-align: right">
              <div>
                <el-button type="text" @click="addDetailRow"
                  >继续添加</el-button
                >
              </div>
              <div>总价：{{ totalPrice | currency }} 万元</div>
            </div>
          </div>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 69px">
        <el-button type="primary" @click="addGoodsMoneyLoan(1)">提交</el-button>
        <el-button @click="cancel">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import qs from "qs";
export default {
  data() {
    var loanMoney_rule = (rule, value, callback) => {
      if (Number(value) > Number(this.bidProject.contractMoney)) {
        callback(new Error("超过合同金额，请重新输入"));
      } else if (Number(value) && Number(value) % 1 == 0) {
        callback();
      } else {
        callback(new Error("请输入贷款金额(整数型)"));
      }
    };
    var googsMoney_rule = (rule, value, callback) => {
      if (Number(value) && Number(value) % 1 == 0) {
        callback();
      } else {
        callback(new Error("请输入中标金额(整数型)"));
      }
    };
    var returnPeriod_rule = (rule, value, callback) => {
      if (Number(value) && Number(value) % 1 == 0) {
        callback();
      } else {
        callback(new Error("请输入回款周期"));
      }
    };
    return {
      bidProject: {},
      form: {
        status: "",
        bidProjectId: "",

        googsMoney: "",
        returnPeriod: "",
        loanMoney: "",
        productCompany: "",
        detailList: [
          {
            productName: "",
            productPrice: 0,
            productAmount: 0,
          },
        ], // 产品列表 productName  productPrice  productAmount
      },
      goodsMoneyLoanId: "",
      goodsMoneyLoanStatus: "",
      rules: {
        loanMoney: [
          {
            required: true,
            validator: loanMoney_rule,
            trigger: "blur",
          },
        ],
        googsMoney: [
          {
            validator: googsMoney_rule,
            trigger: "blur",
          },
        ],
        returnPeriod: [
          {
            validator: returnPeriod_rule,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    totalPrice() {
      let total = 0;
      this.form.detailList.forEach((item) => {
        let price = item.productPrice * item.productAmount;
        total += price;
      });
      return total / 10000;
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$axios
        .get(`/bidProject/bidProjectDetails/${this.$route.params.id}`)
        .then((res) => {
          if (res.state == 200) {
            this.bidProject = res.data.bidProject;
            if (res.data.goodsMoneyLoanAndList.goodsMoneyLoan) {
              let goodsMoneyLoan =
                res.data.goodsMoneyLoanAndList.goodsMoneyLoan;
              this.goodsMoneyLoanStatus = goodsMoneyLoan.status;
              this.goodsMoneyLoanId = goodsMoneyLoan.id;
              this.form.googsMoney = goodsMoneyLoan.googsMoney;
              this.form.returnPeriod = goodsMoneyLoan.returnPeriod;
              this.form.loanMoney = goodsMoneyLoan.loanMoney;
              this.form.productCompany = goodsMoneyLoan.productCompany;
            }
            if (res.data.goodsMoneyLoanAndList.goodsMoneyLoanDetail) {
              this.form.detailList =
                res.data.goodsMoneyLoanAndList.goodsMoneyLoanDetail;
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    addDetailRow() {
      this.form.detailList.push({
        productName: "", // 产品
        productPrice: 0, // 价格
        productAmount: 0, // 数量
      });
    },
    removeDetailRow(index) {
      this.form.detailList.splice(index, 1);
    },
    paramsToFormData(obj) {
      const formData = new FormData();
      Object.keys(obj).forEach((key) => {
        if (obj[key] instanceof Array) {
          obj[key].forEach((item) => {
            formData.append(key, item);
          });
          return;
        }
        formData.append(key, obj[key]);
      });
      return formData;
    },
    async addGoodsMoneyLoan(status) {
      let _this = this;
      _this.form.status = status;
      _this.form.bidProjectId = _this.$route.params.id;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let url = "";
          let data = {};
          if (_this.goodsMoneyLoanStatus === 3) {
            url = "/goodsMoneyLoan/updateGoodsMoneyLoan";
            data = {
              id: _this.goodsMoneyLoanId,
              bidProjectId: _this.form.bidProjectId,
              googsMoney: _this.form.googsMoney,
              returnPeriod: _this.form.returnPeriod,
              loanMoney: _this.form.loanMoney,
              productCompany: _this.form.productCompany,
              detailList: _this.form.detailList.map((item) => {
                return {
                  id: item.id || undefined,
                  productName: item.productName, // 产品
                  productPrice: item.productPrice, // 价格
                  productAmount: item.productAmount, // 数量
                };
              }),
              status: _this.form.status,
            };
          } else {
            url = "/goodsMoneyLoan/addGoodsMoneyLoan";
            data = {
              bidProjectId: _this.form.bidProjectId,
              googsMoney: _this.form.googsMoney,
              returnPeriod: _this.form.returnPeriod,
              loanMoney: _this.form.loanMoney,
              productCompany: _this.form.productCompany,
              detailList: _this.form.detailList.map((item) => {
                return {
                  id: item.id || undefined,
                  productName: item.productName, // 产品
                  productPrice: item.productPrice, // 价格
                  productAmount: item.productAmount, // 数量
                };
              }),
              status: _this.form.status,
            };
          }
          await _this.$axios
            .post(url, qs.stringify(data, { allowDots: true }), {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then((res) => {
              console.log(res);
              if (res.state == 200) {
                this.$message.success(res.message);
                _this.$router.back();
              } else if (res.state == 400) {
                this.$alert(res.message, "", {
                  confirmButtonText: "确定",
                });
              }
            });
        }
      });
    },
    cancel() {
      this.$router.back();
    },
  },
  watch: {
    "$route.params.id": function () {
      this.getDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap-form-group {
  width: 941px;
  border: 1px solid #ededed;
  padding: 64px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .wrap-form {
    width: 350px;

    .input-row {
      display: flex;
    }
    .el-form-item--mini {
      width: 800px;
    }
  }
}
</style>